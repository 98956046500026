@import url(https://fonts.googleapis.com/css?family=Rubik:400,900);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,900);
body {
    font-family: 'Raleway', sans-serif;
}

/*------------------------------------------------------------------
------------------------MENU---------------------------------------
------------------------------------------------------------------*/

.brand {
    width: 100%;
    background: #F1F4F9;
    box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #e2dfdf;
    color: #2C3342;
    position: fixed;
    top: 0;
    z-index: 10;
}

.logo img {
    max-width: 70px;
    max-height: 70px;
    float: left;
    padding: 10px 0;
}

h6 {
    font-weight: 600;
}

.brand label {
    color: #2C3342;
    margin-top: 15px;
    font-weight: 550;
}

.brand select {
    background: transparent;
    border: none;
    color: #2C3342;
    padding: 21px 12px;
}

.brand option {
    color: #333;
    padding: 20px 0;
}

.select-empresa {
    float: right;
}

.navbar-brand {
    display: none;
}

.navbar-nav.compania {
    border-left: 1px solid #e2dfdf;
    padding-left: 15px;
}

.nav-item {
    color: #2C3342;
    font-size: 1rem;
}

.nav-link {
    color: #fff;
}

.dropdown-item:hover {
    background: #37C480;
    color: #fff;
}

.user.navbar-nav li a.nav-link {
    color: #2C3342;
}

.user.navbar-nav li a i {
    margin-right: 10px;
}

.navbar-light .navbar-nav .active>.nav-link {
    color: #D71921;
    border-bottom: 1px solid #D71921;
}

/*------------------------------------------------------------------
------------------------MAIN---------------------------------------
------------------------------------------------------------------*/

.content {
    margin-bottom: 100px;
}

.content-header {
    margin-top: 66px;
}

.main-titles {
    background: #F1F4F9;
    padding: 40px 0;
}

.busqueda {
    margin-top: -40px;
}

.busqueda label {
    color: #2C3342;
    font-size: 18px;
    font-weight: bold;
}

.busqueda input {
    box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.1);
}

.busqueda button {
    margin-top: 33px;
    background-color: #D71921;
    border: 1px solid #D71921;
}

.btn.btn-success {
    background-color: #D71921;
    border: 1px solid #D71921;
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle, .btn-success.focus, .btn-success:focus, .btn-success:hover {
    color: #fff;
    background-color: #D71921;
    border-color: #D71921;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5)!important;
}

.btn.btn-secondary {
    background-color: #D0DAE5;
    border: 1px solid #D0DAE5;
    color: #2C3342;
}

.table.borderless td, .table.borderless th {
    border: none;
}

.border-left {
    border-left: 1px solid #B7C6D5;
}

p.sin-exist {
    color: #E81010;
}

table.existencias thead {
    background: #F1F4F9;
}

.table-bordered thead {
    background: #A51D23;
    color: #fff;
}

td.logo-compañia img {
    max-height: 35px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #FAFCFF;
}

.nav-tabs {
    padding-top: 15px;
    padding-left: 23px;
    margin-top: -59px;
}

.nav-tabs button.nav-link {
    color: #646E7A;
    font-weight: bold;
    padding-right: 30px;
    font-size: 18px;
}

.nav-tabs .nav-link.active {
    color: #D71921;
    font-weight: bold;
    background-color: transparent;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: none;
    border-bottom: 2px solid #D71921;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-top: none;
    border-right: none;
    border-left: none;
}

.tab-content {
    margin: 30px;
    padding-bottom: 20px;
}

footer {
    background-color: #F1F4F9;
    font-size: 12px;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
}

footer a {
    padding: 0 10px;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
}

@media only screen and (max-width: 600px) {
    .logo {
        display: none;
    }
    .brand {
        position: relative;
        z-index: 0;
    }
    .empresa {
        display: none;
    }
    .content {
        margin-top: 0;
    }
    .content-header {
        margin-top: 0;
    }
    .main-titles {
        padding: 0;
    }
    .main-titles img {
        max-width: 100px;
    }
    .busqueda {
        margin-top: 10px;
    }
    .nav-tabs{
        margin-top: 0;
    }
}

/*Tabla Paginacion*/

.react-bootstrap-table-page-btns-ul {
    float: right;
    margin-top: 0px !important;
}

.react-bootstrap-table-pagination-list-hidden {
    display: none;
}

.pagination {
    padding-left: 0;
    margin: 0px;
    border-radius: 4px;
    float: right;
}

.react-bootstrap-table-pagination-total {
    margin-left: 10px;
}

/*Datepicker*/

.react-datepicker-wrapper {
    width: 100%;
}

.datepickerclass {
    width: 100%;
    display: block;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/*Search Grid*/
.search-label {
    width: 100%;
}

/*OTROS*/
.btn-reemplazos {
    padding: 0px;
    border: 0px;
}

.font-small {
    font-size: 12px;
    float: right;
    color: #D71921;
}
/*------------------------------------------------------------------
------------------------Login---------------------------------------
------------------------------------------------------------------*/
body {
    font-family: 'Raleway', sans-serif;
}

.login-bg {
    margin-top: 10%;
}

.background {
    height: 100%;
    background: #CCCCCC;
    text-align: center;
    position: fixed;
    width: 33.33%;
}

.background h1 {
    padding-top: 40%;
    text-align: center;
    color: #000;
    margin-bottom: 10%;
    font-size: 35px;
    font-weight: bold;
}

.background img {
    max-width: 100%;
    text-align: center;
}

.logos {
    display: flex;
}

.logos img {
    max-width: 100%;
}

.logos div.imagen {
    flex-grow: 1;
}

.login-bg p.subtitle {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 40px;
    color: #000;
    font-weight: lighter;
    font-size: 30px;
}

.login-bg input.login[type="text"], input.login[type="password"] {
    margin-bottom: 50px;
    margin-left: 15%;
    display: block;
    width: 70%;
    height: 45px;
    padding: 6px 12px;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
    background-image: none;
    border: 1px solid #B7C6D5;
    border-radius: 6px;
    box-shadow: 0 0 0 0;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    box-shadow: -2px 10px 5px -9px rgba(138, 138, 138, 1);
}

.login-bg label {
    font-weight: 500;
    font-size: 16px;
    color: #2C3342;
    font-weight: bold;
    margin-top: 15px;
    margin-left: 15%;
}

.login-bg input.login[type="text"]::-webkit-input-placeholder, input.login[type="password"]::-webkit-input-placeholder {
    color: #333;
}

.login-bg input.login[type="text"]:-ms-input-placeholder, input.login[type="password"]:-ms-input-placeholder {
    color: #333;
}

.login-bg input.login[type="text"]::-ms-input-placeholder, input.login[type="password"]::-ms-input-placeholder {
    color: #333;
}

.login-bg input.login[type="text"]::placeholder, input.login[type="password"]::placeholder {
    color: #333;
}

.login-bg .btn-iniciar {
    background: #DA2B32;
    padding: 10px 30px;
    font-size: 0.9em;
    margin-bottom: 15px;
    margin-top: 15px;
    color: #fff;
    width: 70%;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 700;
}

.login-bg .btn-iniciar i {
    margin-left: 9px;
}

.login-bg .btn-iniciar:hover {
    background: #9B0B11;
}

.footer-login {
    background-color: rgba(0, 0, 0, 0);
    position: static;
    font-size: 12px;
    padding: 10px;
    bottom: 0;
}

.footer-login a{
    padding: 0 10px;
}

@media only screen and (max-width: 766px) {
    .background {
        display: none;
    }
    .login-bg {
        width: 90%;
        margin-left: 5%;
    }
    .login-bg .login-bg {
        width: 100%;
        background: #2f486ab3;
        box-shadow: none;
        padding: 0;
        overflow-x: hidden;
    }
    .login-bg input.login[type="text"], input.login[type="password"] {
        width: 100%;
        border-radius: 5px;
        height: 50px;
        margin: 1px;
        font-size: 18px;
        background: #f1f4ff2e;
    }
    .login-bg input.login[type="text"]::-webkit-input-placeholder, input.login[type="password"]::-webkit-input-placeholder {
        color: #ffffffad;
    }
    .login-bg input.login[type="text"]:-ms-input-placeholder, input.login[type="password"]:-ms-input-placeholder {
        color: #ffffffad;
    }
    .login-bg input.login[type="text"]::-ms-input-placeholder, input.login[type="password"]::-ms-input-placeholder {
        color: #ffffffad;
    }
    .login-bg input.login[type="text"]::placeholder, input.login[type="password"]::placeholder {
        color: #ffffffad;
    }
    .login-bg h1 {
        color: #fff;
        margin-top: 90px;
    }
    .login-bg .btn-iniciar {
        width: 80%;
        padding: 17px 30px;
    }
    .login-bg label {
        margin-left: 0;
    }
}

@media only screen and (max-width: 350px) {
    .login-bg {
        width: 90%;
        margin-left: 5%;
    }
    .logos {
        display: block;
    }
    .logos div.imagen {
        display: inline-block;
        padding-top: 10px;
    }
}
