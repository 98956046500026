/*------------------------------------------------------------------
------------------------Login---------------------------------------
------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Rubik:400,900');
body {
    font-family: 'Raleway', sans-serif;
}

.login-bg {
    margin-top: 10%;
}

.background {
    height: 100%;
    background: #CCCCCC;
    text-align: center;
    position: fixed;
    width: 33.33%;
}

.background h1 {
    padding-top: 40%;
    text-align: center;
    color: #000;
    margin-bottom: 10%;
    font-size: 35px;
    font-weight: bold;
}

.background img {
    max-width: 100%;
    text-align: center;
}

.logos {
    display: flex;
}

.logos img {
    max-width: 100%;
}

.logos div.imagen {
    flex-grow: 1;
}

.login-bg p.subtitle {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 40px;
    color: #000;
    font-weight: lighter;
    font-size: 30px;
}

.login-bg input.login[type="text"], input.login[type="password"] {
    margin-bottom: 50px;
    margin-left: 15%;
    display: block;
    width: 70%;
    height: 45px;
    padding: 6px 12px;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
    background-image: none;
    border: 1px solid #B7C6D5;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 0 0;
    box-shadow: 0 0 0 0;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -webkit-box-shadow: -2px 10px 5px -9px rgba(138, 138, 138, 1);
    -moz-box-shadow: -2px 10px 5px -9px rgba(138, 138, 138, 1);
    box-shadow: -2px 10px 5px -9px rgba(138, 138, 138, 1);
}

.login-bg label {
    font-weight: 500;
    font-size: 16px;
    color: #2C3342;
    font-weight: bold;
    margin-top: 15px;
    margin-left: 15%;
}

.login-bg input.login[type="text"]::placeholder, input.login[type="password"]::placeholder {
    color: #333;
}

.login-bg .btn-iniciar {
    background: #DA2B32;
    padding: 10px 30px;
    font-size: 0.9em;
    margin-bottom: 15px;
    margin-top: 15px;
    color: #fff;
    width: 70%;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 700;
}

.login-bg .btn-iniciar i {
    margin-left: 9px;
}

.login-bg .btn-iniciar:hover {
    background: #9B0B11;
}

.footer-login {
    background-color: rgba(0, 0, 0, 0);
    position: static;
    font-size: 12px;
    padding: 10px;
    bottom: 0;
}

.footer-login a{
    padding: 0 10px;
}

@media only screen and (max-width: 766px) {
    .background {
        display: none;
    }
    .login-bg {
        width: 90%;
        margin-left: 5%;
    }
    .login-bg .login-bg {
        width: 100%;
        background: #2f486ab3;
        box-shadow: none;
        padding: 0;
        overflow-x: hidden;
    }
    .login-bg input.login[type="text"], input.login[type="password"] {
        width: 100%;
        border-radius: 5px;
        height: 50px;
        margin: 1px;
        font-size: 18px;
        background: #f1f4ff2e;
    }
    .login-bg input.login[type="text"]::placeholder, input.login[type="password"]::placeholder {
        color: #ffffffad;
    }
    .login-bg h1 {
        color: #fff;
        margin-top: 90px;
    }
    .login-bg .btn-iniciar {
        width: 80%;
        padding: 17px 30px;
    }
    .login-bg label {
        margin-left: 0;
    }
}

@media only screen and (max-width: 350px) {
    .login-bg {
        width: 90%;
        margin-left: 5%;
    }
    .logos {
        display: block;
    }
    .logos div.imagen {
        display: inline-block;
        padding-top: 10px;
    }
}